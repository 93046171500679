<template>
  <section id="firebaseui-auth-container" />
</template>
<script>
  import firebase from 'firebase'
  import * as firebaseui from 'firebaseui'
  import 'firebaseui/dist/firebaseui.css'
  import i18n from '../i18n'

  export default {
    name: 'Login',
    data () {
      return {}
    },

    mounted () {
      const user = this.$firebaseAuth.currentUser
      if (user) {
        this.$router.push('/' + i18n.locale + '/user/account')
      }
      console.log(firebaseui)
      let ui = firebaseui.auth.AuthUI.getInstance()
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(this.$firebaseAuth)
      }
      const uiConfig = {
        signInSuccessUrl: '/' + i18n.locale + '/user/account',
        signInFlow: 'popup',
        signInOptions: [
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            customParameters: { hd: 'oriaagriculture.com' },
          },

        ],
      }
      ui.start('#firebaseui-auth-container', uiConfig)
    },
  }
</script>
